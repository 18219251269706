import React from 'react';
import Logo from './Logo';
import PieChartIconComponent from './PieChartIcon';
import './TrustScoreReport.css';
import BigNumber from './BigNumber';
import ReportDetailLink from './ReportDetailLink';
import RecoValidity from './RecoValidity';
import RecoCompleteness from './RecoCompleteness';
import RecoUniqueness from './RecoUniqueness';
import { sortBy } from '../sort';

class TrustScoreReport extends React.Component {
	render() {
		const { content } = this.props;
		const totalCells = content.file_metadata.row_count * content.file_metadata.column_count;
		let items;
		if (content.column_stats) {
			items = content.column_stats.sort(sortBy('id')).map(item => (
				<tr key={item.id}>
					<th scope="col">{item.id}</th>
					<td>{item.name}</td>
					<td>{item.data_type}</td>
					<td>
						{item.semantic_type}{' '}
						{item.semantic_type === '' ? null : '(' + item.semantic_type_frequency.toFixed(2) + '%)'}
					</td>
					<td>{item.contains_pii ? 'Y' : ''}</td>
					<td>{item.used_for_validation}</td>
					<td>{item.valid}</td>
					<td>{item.empty}</td>
					<td>{item.invalid}</td>
					<td>{item.distinct}</td>
					<td>{item.weight.toFixed(3)}</td>
					<td>{item.weighted_validity_score.toFixed(3)}</td>
					<td>{item.weighted_completeness_score.toFixed(3)}</td>
					<td>{item.diversity_score >= 0 ? item.diversity_score.toFixed(3) : 'N/A'}</td>
					<td>{item.freshness_score >= 0 ? item.freshness_score.toFixed(3) : 'N/A'}</td>
				</tr>
			));
		}


		const columnsWithHighSkewness = content.recommendation.diversity_breakdown.remarked_columns;
		const diversityRecommendationItems = columnsWithHighSkewness.map(item => (
			<tr key={item.name}>
				<td>{item.name}</td>
				<td>
					{item.diversity_score !== undefined? item.diversity_score.toFixed(3) + "/1" : ''}
				</td>
			</tr>
		));

		const columnsWithSecurityIssue = content.recommendation.security_breakdown.remarked_columns;
		const securityRecommendationItems = columnsWithSecurityIssue.map(item => (
			<tr key={item.name}>
				<td>{item.name}</td>
				<td>
					
				</td>
			</tr>
		));

		const columnsUsedToComputeTimeliness = content.recommendation.timeliness_breakdown.remarked_columns;
		const timelinessRecommendationItems = columnsUsedToComputeTimeliness.map(item => (
			<tr key={item.name}>
				<td>{item.name}</td>
				<td>
					{item.freshness_score !== undefined? item.freshness_score.toFixed(3) + "/1" : ''}
				</td>
			</tr>
		));

		const columnsWithValidityIssue = content.recommendation.validity_breakdown.remarked_columns;
		const validityRecommendationItems = columnsWithValidityIssue.map(item => (
			<tr key={item.name}>
				<td>{item.name}</td>
				<td>
					<b>{(item.invalid * 100.0 / item.total).toFixed(1)}%</b> invalid
				</td>
			</tr>
		));

		const columnsWithCompletenessIssue = content.recommendation.completeness_breakdown.remarked_columns;
		const completenessRecommendationItems = columnsWithCompletenessIssue.map(item => (
			<tr key={item.name}>
				<td>{item.name}</td>
				<td>
					<b>{(item.empty * 100.0 / item.total).toFixed(1)}%</b> empty
				</td>
			</tr>
		));
		if (!content) {
			return null;
		}
		return (
			<div>
				<div className="mt-0 report-summary">
					<div className="Report__logo">
						<a href="https://www.qlik.com/" title="Qlik Logo"><Logo /></a>
					</div>
					{/* <center className="d-print-none my-2">
                        <button className="btn btn-light" onClick={() => window.print()}>Print Report</button>
                    </center> */}
					<div className="container">
						{/*<!-- Report Summary -->*/}
						<div className="row my-5">
							<div className="col-md-7 text-left">
								<h1>Your Qlik Talend Trust Score&trade; for AI</h1>
								<p />
								<p>
									On a scale where 100 means a fully trusted and accurate dataset,
									{' '}<strong>your Qlik Talend Trust Score&trade; for AI is {content.trust_score_for_ai.overall.toFixed(0)}.</strong>
									{content.trust_score_for_ai.overall < 100 && (
										<>{' '}(Ready to raise your score now ? We can help.)</>
									)}
								</p>
								<a
									type="button"
									className="btn btn-secondary btn-lg"
									id="button-section-header"
									href="https://www.qlik.com/us/try-or-buy/buy-now"
									role="button"
								>
									Contact us
								</a>
								<p />
								<p>Let’s dig into your data and see what’s affecting your score.</p>
							</div>
							<div className="col-md-5">
								<div className="Report__gauge">
									<BigNumber value={content.trust_score_for_ai.overall.toFixed(0)}>
										{/*}<image href="/talend_trust_score.svg" x="90" y="230" width="200px"></image>{*/}
									</BigNumber>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2>Calculating your score</h2>
							<p>
								The Qlik Talend Trust Score&trade; is an excellent foundation for assessing data quality, however when it comes to 
								Artificial Intelligence (AI), additional considerations must be integrated to ensure the data is trustworthy 
								and reliable. Diversity ensures that data encompasses a wide range of values, helping to avoid bias and 
								enabling AI models to generalize well across various scenarios, promoting fairness and accuracy. Timeliness 
								guides AI systems involved in real-time decision-making, as outdated data can lead to incorrect predictions 
								and decisions. Security preserves the confidentiality, and integrity of data used in AI systems. Integrating 
								dimensions such as these enhances the overall quality and reliability of data used in AI..
							</p>
							<p>
								In the report below, each aspect gets its own detailed score, which comes with specific insights and 
								recommended actions. This report's output is directional for informational purposes only. It serves as 
								a general guide and should not be solely relied on for real-world production AI-based applications.  
							</p>
							<p>
								<i>Actual implementation will depend on specific circumstances, data types, and regulatory requirements. 
								It is crucial to consult with qualified professionals, including data scientists, security experts, 
								and legal counsel, to develop a robust and compliant AI strategy to serve your unique needs.</i>
							</p>
						</div>
						<div className="col-lg-4">
							<div className="Report__portlet">
								<h5>WHY TRUSTED DATA FOR AI?</h5>
								<p>
									Imagine building a house on shaky ground. That's essentially what using untrusted data does for AI. 
									Biases, lack of most up-to-date data, inconsistencies or errors in the data can lead AI systems astray.  
									However, when you trust the data powering your AI, you're laying a solid foundation. This trustworthy 
									data allows AI to learn effectively, and generate reliable insights. When users can be confident in the 
									quality of the underlying data, they are more likely to trust the AI's outputs and embrace its capabilities.
								</p>
							</div>
						</div>
						<div className="col-lg-8 text-left">
							<ReportDetailLink
								id="diversity"
								title="Diversity"
								icon={`${process.env.PUBLIC_URL}/diversity.svg`}
								description="Are you betting your AI models on narrow and siloed datasets?"
							/>
							<ReportDetailLink
								id="timeliness"
								title="Timeliness"
								icon={`${process.env.PUBLIC_URL}/timeliness.svg`}
								description="Does your dataset for AI contain up-to-date records?"
							/>
							<ReportDetailLink
								id="security"
								title="Security"
								icon={`${process.env.PUBLIC_URL}/security.svg`}
								description="Are your datasets leaking sensitive PII information?"
							/>
							<ReportDetailLink
								id="validity"
								title="Validity"
								icon={`${process.env.PUBLIC_URL}/most-trusted.svg`}
								description="Is your data correctly formatted and stored in the right way?"
							/>
							<ReportDetailLink
								id="completeness"
								title="Completeness"
								icon={`${process.env.PUBLIC_URL}/list.svg`}
								description="Does your dataset include values for all the fields required?"
							/>
							<ReportDetailLink
								id="uniqueness"
								title="Uniqueness"
								icon={`${process.env.PUBLIC_URL}/numbers.svg`}
								description="Is your data free from duplicates and dummy entries?"
							/>
						</div>
					</div>
				</div>

				<hr className="col-md-5 text-center d-print-none" />
				<p className='pagebreak' />

				<div className="container">
					{/*<!-- Data Diversity Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="diversity">Data Diversity Assessment</h2>
							<p />
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								Data diversity refers to the practice of incorporating a wide variety of data sources and formats 
								into your AI models. Ensuring data diversity is equally important, particularly when working with AI. 
								Just like ensuring data validity entails conforming to specific data and business rules, having a 
								diverse dataset for AI use-cases is crucial, eliminating biases, such as social inequality.
							</p>
							<p>
								Bias can creep into AI models through a variety of ways, but the most common culprit is <b>skewed</b> training data. 
								Imagine a credit scoring algorithm trained only on data from high-income earners. The resulting model might 
								unfairly deny loans to applicants from lower-income backgrounds.
							</p>
						</div>
						<div className="col-lg-4 m-auto text-center">
							{diversityRecommendationItems.length > 0 && (
								<>
									<h5>
										Top <b>numeric columns</b> with lowest diversity score:
									</h5>

									<table className="table inconsolata">
										<tbody>{diversityRecommendationItems}</tbody>
									</table>

									<h5>
										<i>* lower score indicates higher skewness</i>
									</h5>
									
								</>
							)}
							{diversityRecommendationItems.length === 0 && (
								<>
									<h5>
										No <b>numeric column</b> available for data assessment.
									</h5>
								</>
							)}
						</div>
					</div>

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>
							<ul>
								<li>
									<b>Go beyond the silo:</b> Do not limit your data sources. Look to incorporate information from diverse sets of sources 
									for the domain of data. Unlock data from various locations, like cloud storage, on-premise databases, and even 
									unstructured files. Qlik Talend platform offers a robust platform equipped with connectors to a vast array of data 
									sources within your data ecosystem, allowing you to tap into data lakes, cloud databases and data warehouses, 
									and even social media APIs to enrich your AI models.
								</li>
								<li>
									<b>Embrace different formats:</b> Text documents, social media data, and even sensor readings can all contribute valuable 
									perspectives. While traditional data analysis tools often struggle with unstructured formats, the Qlik Talend platform 
									extends its capabilities beyond structured data.
								</li>
								<li>
									<b>Seek out a wide range of data points:</b> Consider including information beyond traditional metrics. Ensure that you 
									have a wide range of data that covers different patterns and variations relevant to the problem. This can help create a 
									more holistic picture and reduce bias based on limited attributes.
								</li>
							</ul>
						</div>
						<div className="col-lg-4 mt-5">
							{/*}<RecoValidity data={content} />{*/}
						</div>
					</div>

					<hr className="col-md-10 text-center d-print-none" />
					<p className="pagebreak" />

					{/*<!-- Data Timeliness Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="timeliness">Data Timeliness Assessment</h2>
							<p />
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								Planning a trip based on yesterday's weather forecast is not ideal, and the same applies to AI.
								Data diversity fuels Machine Learning (ML) and Generative AI (Gen AI), but the freshness of that 
								data is equally critical. Outdated data can lead AI models astray, resulting in inaccurate or
								irrelevant outcomes. <b>Up-to-date information</b> enables AI to make informed decisions, optimize
								processes, and achieve superior results.
							</p>
						</div>
						<div className="col-lg-4 m-auto text-center">
							{timelinessRecommendationItems.length > 0 && (
								<>
									<h5>
										Timeliness detected as per the following date/time column:
									</h5>

									<table className="table inconsolata text-center">
										<tbody>{timelinessRecommendationItems}</tbody>
									</table>

									<h5>
										<i>* higher score closer to 1 indicates fresher data in a 30-day window</i>
									</h5>
								</>
							)}
							{timelinessRecommendationItems.length === 0 && (
								<>
									<h5>
										No datetime column available for data assessment.
									</h5>
								</>
							)}
						</div>
					</div>

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>
							<ul>
								<li>
									<b>Low-Latency, Real-Time Pipelines:</b> To guarantee timely data for your AI initiatives,
									prioritize building and deploying low-latency, real-time data pipelines. These pipelines
									ensure near-instantaneous data transfer, minimizing the time between data generation
									and its availability for AI models. With the Qlik Talend platform, you can automatically
									move data as well as design, create, and continuously update data warehouses and
									data lakes on any cloud platform.
								</li>
								<li>
									<b>Tap into Change Data Capture (CDC):</b> For data residing in relational databases,
									consider leveraging Change Data Capture (CDC) technologies. CDC efficiently captures
									only the modified or updated data, streamlining the transfer process and minimizing
									the load on your systems. With the Qlik Talend platform, you can tap into CDC data
									sources, and bring fresh data into your data pipelines as it gets generated or updated.
								</li>
							</ul>
						</div>
						<div className="col-lg-4 mt-5">
						</div>
					</div>

					<hr className="col-md-10 text-center d-print-none" />
					<p className="pagebreak" />

					{/*<!-- Data Security Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="security">Data Security Assessment</h2>
							<p />
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								Unsecured datasets used for AI applications is akin to leaving a vault wide open, exposing
								sensitive information to theft, manipulation, or disruption by malicious actors. Responsible and
								trustworthy AI development hinges on securing data, fostering trust, and minimizing the
								potential for misuse of powerful AI technology.
							</p>
							<p>
								Accidental leakage of sensitive data or malicious manipulation of training data can result in
								violation of compliance regulations, and businesses facing hefty fines. Robust data security
								measures are essential to safeguard Personally Identifiable Information (PII) and ensure user 
								privacy.
							</p>
						</div>

						<div className="col-lg-4 m-auto text-center">
							{securityRecommendationItems.length > 0 && (
								<>
									<h5>
										<b>List of columns</b> that possibly contain PII data:
									</h5>

									<table className="table inconsolata text-center">
										<tbody>{securityRecommendationItems}</tbody>
									</table>
								</>
							)}
							{securityRecommendationItems.length === 0 && (
								<>
									<h5>
										All good! No column detected as PII.
									</h5>
								</>
							)}
						</div>
					</div>

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>
							<ul>
								<li>
									<b>PII data detection and classification:</b> This involves identifying, categorizing, and
									labeling data based on its sensitivity, serving as a foundation of AI datasets. With the
									Qlik Talend platform, PII data can be detected, ensuring sensitive information is
									properly identified.
								</li>
								<li>
									<b>Data protection:</b> This involves masking, tokenization, and encryption of data to make it
									unreadable for unauthorized users, while preserving its usability for authorized users
									and processes. The Qlik Talend platform has several built-in data protection features,
									including hashing, masking, and encryption to secure sensitive data at rest and in transit.
								</li>
							</ul>
						</div>
						<div className="col-lg-4 mt-5">
						</div>
					</div>

					<hr className="col-md-10 text-center d-print-none" />
					<p className="pagebreak" />

					{/*<!-- Data Validity Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="validity">Data Validity Assessment</h2>
							<p />
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								Data Validity is a measure of how effectively your organization conforms to a
								business process. Every piece of information that comes into your system must
								conform to specific rules for type, format, and range; common rules are for things
								like phone number, ZIP code, or email address.
							</p>
							<p>
								While it may seem small, even slight variances, such as “New York” versus “NY” in a
								state field, could skew your analytics and lead to the wrong decision about where to
								open another store or increase your marketing spend. That is why data validity is so
								critical to your data trust.
							</p>
							{content.recommendation.validity_breakdown.total_invalid_count > 0 && (
								(content.recommendation.validity_breakdown.score_level === 'high' ?
									<p>
										Overall, you’re in pretty good shape! If you want to go the extra mile, in the table we've listed some areas
										that could use a little attention.
									</p>
									:
									<p>
										We found a few issues with your data validity. In the table we've listed some areas that could use
										your attention.
									</p>
								)
							)}
						</div>
						<div className="col-lg-4 m-auto text-center">
							<h5>
								<b>Data Validity</b> breakdown:
							</h5>
							<div className="d-flex justify-content-center">
								<PieChartIconComponent
									display="xlarge"
									model={[
										{
											color: 'jaffa',
											percentage:
												100.0 * content.recommendation.validity_breakdown.total_invalid_count / totalCells,
										},
									]}
								>
									Invalid values
								</PieChartIconComponent>
							</div>
							{validityRecommendationItems.length > 0 && (
								<>
									<h5>
										<b>Top columns</b> that could use attention:
									</h5>

									<table className="table inconsolata">
										<tbody>{validityRecommendationItems}</tbody>
									</table>
								</>
							)}
						</div>
					</div>

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>
							{(content.recommendation.validity_breakdown.score_level === 'high' ?
								<p>
									We didn’t find many issues with this dataset, which shows that you take data validity
									seriously. Talend can help you streamline and automate data validity in the future with
									the following functionality:
								</p>
								:
								<p>
									Not to worry: you don’t need a heavy manual process to repair this dataset. Not only
									does Talend clean new data before it enters your system, we have built-in checks at
									every stage of the data lifecycle to identify and correct common data validity
									errors. Here are some of our capabilities that will not only address the concerns
									within this dataset but can help improve your data validity score across all your
									data:
								</p>
							)}
						</div>
						<div className="col-lg-4 mt-5">
							<RecoValidity data={content} />
						</div>
					</div>

					<hr className="col-md-10 text-center d-print-none" />
					<p className="pagebreak" />

					{/*<!-- Data Completeness Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="completeness">Data Completeness Assessment</h2>
							<p />
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								Data completeness looks at the data required by your system and tells you how much
								of it is actually available. Data completeness may sound simple, but certain
								complexities often go overlooked. For example, if a name record is missing a first
								name or gender, that record may be incomplete. If you try to use or segment that
								data, or combine it with data from other systems, you might have to stop everything
								for time-consuming manual data cleanups or, even worse, the missing data might go
								unnoticed and result in serious errors.
							</p>

							{content.recommendation.completeness_breakdown.total_empty_count > 0 && (
								<p>
									When we analyzed your dataset of <strong>{totalCells}</strong> expected pieces of data,
									we found that
									{' '}<strong>{content.recommendation.completeness_breakdown.total_empty_count}</strong>{' '}
									were missing. The table shows which columns are the least complete.
								</p>
							)}
						</div>
						<div className="col-lg-4 mt-5 text-center">
							<h5>
								<b>Data Completeness</b> breakdown:
							</h5>
							<div>
								<PieChartIconComponent
									display="xlarge"
									model={[
										{
											color: 'dove-gray',
											percentage:
												100.0 * content.recommendation.completeness_breakdown.total_empty_count / totalCells,
										},
									]}
								>
									Empty values
								</PieChartIconComponent>
							</div>
							{completenessRecommendationItems.length > 0 && (
								<>
									<h5>
										<b>Top columns</b> that could use attention:
									</h5>

									<table className="table inconsolata">
										<tbody>{completenessRecommendationItems}</tbody>
									</table>
								</>
							)}
						</div>
					</div>

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>

							{(content.recommendation.completeness_breakdown.score_level === 'high' ?
								<p>
									While this dataset ranks high for completeness, not every dataset in your company looks
									like this one. If you partner with Talend, we can provide you with a holistic look at
									your data. You might be interested in some of the other capabilities we have to offer:
								</p>
								:
								<p>
									Resolving your issues with incomplete data could improve your ability to use your data
									effectively. If you partner with Talend, we can provide you with a holistic look at your
									data. You might be interested in some of the other capabilities we have to offer:
								</p>
							)}
						</div>
						<div className="col-lg-4 mt-5">
							<RecoCompleteness data={content} />
						</div>
					</div>

					<hr className="col-md-10 text-center d-print-none" />
					<p className="pagebreak" />

					{/*<!-- Data Uniqueness Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="uniqueness">Data Uniqueness Assessment</h2>
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								It isn’t always easy to identify duplicate records. If four leads come in as ABC
								Company, Abc Inc, ABC Corp., and ABC incorporated, you might think nothing of it.
								But if they all have the address 1111 Acme Road… well, something doesn’t seem right
								there.
							</p>
							<p>
								When it comes to duplicate data, always consider the 1-10-100 rule: if it only costs
								$1 to verify a record as it’s entered, it will cost $10 to cleanse and dedupe it
								later, and $100 if you do nothing. What would that mean for you if you had 10,000
								duplicate records across your organization?
							</p>

							{content.trust_score_for_ai.record_uniqueness < 1 && (
								(content.recommendation.uniqueness_breakdown.score_level === 'high' ?
									<p>
										Good news! In the dataset you provided, we just found
										{' '}<strong>{content.recommendation.uniqueness_breakdown.duplicate_record_count}</strong>{' '}
										duplicates out of <strong>{content.file_metadata.row_count}</strong> total records. If you wanted to go
										even further — or were looking for strategies to apply to other datasets — here are a few things
										to consider:
									</p>
									:
									<p>
										In the dataset you provided, we found
										{' '}<strong>{content.recommendation.uniqueness_breakdown.duplicate_record_count}</strong>{' '}
										duplicates out of <strong>{content.file_metadata.row_count}</strong> total records. We’d recommend
										considering a few strategies to help clean up this data:
									</p>
								)
							)}
						</div>
						<div className="col-lg-4 mt-5 text-center">
							<h5>
								<b>Data Uniqueness</b> breakdown:
							</h5>
							<PieChartIconComponent
								display="xlarge"
								model={[
									{
										color: 'lochmara',
										percentage: (1 - content.trust_score_for_ai.record_uniqueness) * 100,
									},
								]}
							>
								Duplicates
							</PieChartIconComponent>
						</div>
					</div>

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 mt-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>

							{(content.recommendation.uniqueness_breakdown.score_level === 'high' ?
								<p>
									If only your data could stay as clean as it is now forever! But every day new data is
									coming into your system, increasing the chances of adding duplicate records. Talend’s
									advanced functionality can help make that process painless for you and your team:
								</p>
								:
								<p>
									You have some work to do here — and every day new data is coming into your system,
									increasing the chances of adding duplicate records. Talend’s advanced functionality
									can help make that process painless for you and your team:
								</p>
							)}
						</div>
						<div className="col-lg-4 mt-5">
							<RecoUniqueness data={content} />
						</div>
					</div>

				</div>

				<div className="mt-5 report-footer">
					<div className="container">
						<div className="row">
							<div className="col">
								<a
									type="button"
									className="btn btn-outline-secondary btn-lg"
									id="button-section-footer"
									href="/"
									role="button"
								>
									<i className="fa fa-arrow-left" />
									Try another file
								</a>

								<hr className="col-md-12 text-center" />

								<div className="footer-text">
									<p>
										© 1993-2024 QlikTech International AB. All rights reserved.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				{window.location.search.indexOf('debug') !== -1 && (
					<div className="row mt-0 report-detail d-print-none">
						<div className="col-md-8 m-auto text-center">
							<h1>Detailed Metadata</h1>
						</div>
						<div className="col-md-6 m-auto mx-2">
							<table className="table inconsolata">
								<tbody>
									<tr>
										<th scope="col">File Name</th>
										<td>{content.file_metadata.file_name_hash}</td>
									</tr>
									<tr>
										<th scope="col">File Size (KB)</th>
										<td>{content.file_metadata.file_size}</td>
									</tr>
									<tr>
										<th scope="col">File Type</th>
										<td>{content.file_metadata.file_type}</td>
									</tr>
									<tr>
										<th scope="col">Column Count</th>
										<td>{content.file_metadata.column_count}</td>
									</tr>
									<tr>
										<th scope="col">Row Count</th>
										<td>{content.file_metadata.row_count}</td>
									</tr>
									<tr>
										<th scope="col">Encoding</th>
										<td>{content.file_metadata.encoding}</td>
									</tr>
									<tr>
										<th scope="col">Delimiter</th>
										<td>{content.file_metadata.delimiter}</td>
									</tr>
									<tr>
										<th scope="col">Contains Header</th>
										<td>{content.file_metadata.has_header.toString()}</td>
									</tr>
									<tr>
										<th scope="col">Validity</th>
										<td>{content.trust_score_for_ai.validity.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Completeness</th>
										<td>{content.trust_score_for_ai.completeness.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Record Uniqueness</th>
										<td>{content.trust_score_for_ai.record_uniqueness.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Diversity</th>
										<td>{content.trust_score_for_ai.diversity.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Security</th>
										<td>{content.trust_score_for_ai.security.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Timeliness</th>
										<td>{content.trust_score_for_ai.timeliness.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Overall Trust Score for AI</th>
										<td>{content.trust_score_for_ai.overall.toFixed(3)}</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className="mx-5">
							<table className="table table-striped table-bordered inconsolata">
								<thead className="thead-dark">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Column Name</th>
										<th scope="col">Data Type</th>
										<th scope="col">Semantic Type</th>
										<th scope="col">Contains PII</th>
										<th scope="col">Used For Validation</th>
										<th scope="col">Valid</th>
										<th scope="col">Empty</th>
										<th scope="col">Invalid</th>
										<th scope="col">Distinct</th>
										<th scope="col">Weight</th>
										<th scope="col">Weighted Validity</th>
										<th scope="col">Weighted Completeness</th>
										<th scope="col">Diversity Score</th>
										<th scope="col">Freshness Score</th>
									</tr>
								</thead>
								<tbody>{items}</tbody>
							</table>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default TrustScoreReport;
