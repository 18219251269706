import React from 'react';
import './Logo.css';

export default function Logo({ size, ...props }) {
	return (
	<svg version="1.1" id="Layer_2_00000122691654168283297900000009301703023988743064_"
		xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 71.39 29.74"
		xmlSpace="preserve">
	<g>
		<g>
			<path d="M70.47 27.52c.05-.07.07-.15.07-.24s-.02-.17-.07-.24-.1-.11-.16-.14a.38.38 0 0 0-.17-.04h-.66v1.31h.29v-.44h.29l.23.44h.32l-.27-.51a.47.47 0 0 0 .15-.13Zm-.71-.45h.27s.07.01.12.04c.04.03.07.08.07.16s-.02.14-.07.18c-.05.04-.09.06-.13.06h-.27v-.44Z" class="cls-2"/>
			<path d="M71.28 26.97c-.07-.17-.17-.32-.31-.45-.13-.13-.28-.23-.45-.31s-.36-.11-.55-.11-.38.04-.55.11-.32.18-.45.31-.23.28-.3.45c-.07.17-.11.36-.11.55s.04.38.11.55c.07.17.17.32.3.45.13.13.28.23.45.31s.36.11.55.11.38-.04.55-.11.32-.18.45-.31.23-.28.31-.45c.07-.17.11-.36.11-.55s-.04-.38-.11-.55Zm-.37 1.1c-.1.16-.23.29-.39.39-.16.1-.34.15-.54.15s-.38-.05-.54-.15c-.16-.1-.29-.23-.39-.39-.1-.16-.15-.34-.15-.54s.05-.38.15-.54c.1-.16.23-.29.39-.39.16-.1.34-.15.54-.15s.38.05.54.15c.16.1.29.23.39.39.1.16.15.34.15.54s-.05.38-.15.54ZM34.9.65h2.96v28.32H34.9zM42.95 9.19h2.95v19.78h-2.95z" class="cls-2"/>
			<circle cx="44.45" cy="2.67" r="2.02" class="cls-2"/>
			<path d="M66.62 9.19h-4.11l-8.52 7.46-.02-16h-2.94v28.32h2.94v-9.14l8.75 9.14h4.1L56.39 18.39l10.23-9.2zM14.87 26.69c-6.53 0-11.82-5.29-11.82-11.82 0-2.77.96-5.32 2.55-7.34L3.44 5.34A14.922 14.922 0 0 0 0 14.87c0 8.21 6.66 14.87 14.87 14.87 3.56 0 6.83-1.25 9.39-3.34l-2.16-2.19c-2 1.55-4.5 2.47-7.23 2.47Z" class="cls-2"/>
			<path fill="#009445" stroke-width="0" d="m26.31 24.34.17-.17a14.83 14.83 0 0 0 3.26-9.3C29.74 6.66 23.08 0 14.87 0c-3.54 0-6.79 1.24-9.34 3.3-.74.6-1.41 1.26-2.03 1.98-.02.02-.03.04-.05.06l2.16 2.19s.04-.05.06-.07c.59-.74 1.27-1.4 2.02-1.97a11.74 11.74 0 0 1 7.18-2.44c6.53 0 11.82 5.29 11.82 11.82 0 2.75-.94 5.29-2.52 7.3-.57.73-1.23 1.39-1.95 1.96l-.12.09 2.16 2.19 2.54 2.57h4.1l-4.59-4.64Z"/>
		</g>
	</g>
	</svg>
	);
}
