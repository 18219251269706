// import GradientPath from 'gradient-path';
import React from 'react';
import { arc } from 'd3-shape';
import './BigNumber.css';
import { JAFFA, CHESTNUT_ROSE, LIGHTING_YELLOW, RIO_GRANDE } from '../colors';
const arcGen = arc();

const INNER_RADIUS = 31;
const OUTER_RADIUS = 35;
const LOGO_SIZE = 16;
const LOGO_SCALE = 1.5;
const MIN_COEFF = 0.9;
const MAX_COEFF = 2.5;
const MIN_ANGLE = MIN_COEFF * Math.PI;
const MAX_ANGLE = MAX_COEFF * Math.PI;

const ARC_CHEST = {
	fill: 'url(#chestnutjaffa)',
	arc: {
		startAngle: MIN_ANGLE,
		endAngle: 1.5 * Math.PI,
	},
};
const ARC_JAFFA = {
	fill: 'url(#jaffayellow)',
	arc: {
		startAngle: 1.5 * Math.PI,
		endAngle: 2 * Math.PI,
	},
};
const ARC_YELLOW = {
	fill: 'url(#yellowrio)',
	arc: {
		startAngle: 2 * Math.PI,
		endAngle: MAX_ANGLE,
	},
};

function getScoreColor(value) {
	if (value < 25) {
		return CHESTNUT_ROSE;
	} else if (value < 50) {
		return JAFFA;
	} else if (value < 75) {
		return LIGHTING_YELLOW;
	}
	return RIO_GRANDE;
}

function getStartAngle(value) {
	if (value < 33) {
		return MIN_ANGLE;
	} else if (value < 66) {
		return 1.5 * Math.PI;
	}
	return 2 * Math.PI;
}
function getEndAngle(value) {
	// value === 100 => 2.5
	// value === 0 => START_ANGLE
	return ((value / 100) * (MAX_COEFF - MIN_COEFF) + MIN_COEFF) * Math.PI;
}
function getGrayArc(value) {
	return {
		fill: '#ababab',
		arc: {
			startAngle: getEndAngle(value),
			endAngle: MAX_ANGLE,
		},
	};
}

function TLogo(props) {
	return (
		<svg>
			<g {...props}>
				<circle cx="8" cy="8" r={12} fill="White" />
				<path
					className="ti-background"
					fill="#FF6E70"
					d="M16,8 C16,12.4188737 12.4182648,16.0006088 8,16.0006088 C3.58173516,16.0006088 0,12.4188737 0,8 C0,3.58173516 3.58173516,5.34996883e-14 8,5.34996883e-14 C12.4182648,5.34996883e-14 16,3.58173516 16,8"
				/>
				<path
					className="ti-foreground"
					fill="#FFF"
					d="M10.1780168,11.4316034 C10.151395,11.354158 10.1138824,11.282158 10.0648739,11.2162084 C10.0164706,11.1496538 9.97895798,11.1230319 9.95233613,11.1363429 C9.78413445,11.2198387 9.60020168,11.2912336 9.40114286,11.3487126 C9.20208403,11.4061916 9.02904202,11.4352336 8.88322689,11.4352336 C8.7192605,11.4352336 8.59401681,11.397721 8.50810084,11.325721 C8.42157983,11.2525109 8.35986555,11.1169815 8.32235294,10.9203429 C8.28423529,10.7237042 8.26547899,10.4363092 8.26547899,10.0599731 L8.26547899,6.24276303 L9.69337815,6.24276303 C9.74178151,6.24276303 9.78715966,6.19617479 9.82951261,6.10239328 C9.8712605,6.01042689 9.89243697,5.89546891 9.89243697,5.75812437 C9.89243697,5.70004034 9.87791597,5.64861176 9.84887395,5.60504874 C9.82043697,5.56088067 9.78171429,5.53667899 9.73331092,5.5324437 L8.26547899,5.5324437 L8.26547899,3.6792 C8.26547899,3.60235966 8.21465546,3.54790588 8.1245042,3.51099832 C7.95811765,3.45896471 7.86070588,3.63987227 7.86070588,3.63987227 C7.41418487,4.67207395 6.53747899,5.46891429 5.4532437,5.80834286 C5.4532437,5.80834286 5.23179832,5.86098151 5.28867227,6.09271261 C5.29532773,6.11267899 5.30137815,6.13264538 5.31105882,6.15261176 C5.34191597,6.21735126 5.37942857,6.25062857 5.42359664,6.25607395 L6.13391597,6.25607395 L6.13391597,10.6517042 C6.13391597,11.2755025 6.32329412,11.7117378 6.70205042,11.959805 C7.08020168,12.2072672 7.58843697,12.3306958 8.22554622,12.3306958 C8.42521008,12.3306958 8.65633613,12.2937882 8.91952941,12.218763 C9.18272269,12.1431328 9.43078992,12.0469311 9.66312605,11.9295529 C9.89606723,11.8127798 10.0672941,11.6941916 10.1780168,11.5737882 C10.2046387,11.5568471 10.2046387,11.5090487 10.1780168,11.4316034"
				/>
			</g>
		</svg>
	);
}

function getArcs(value) {
	// 0 => 0; 100 =>
	let arcs;
	if (value < 33) {
		arcs = [
			{
				fill: 'url(#chestnutjaffa)',
				arc: {
					startAngle: getStartAngle(value),
					endAngle: getEndAngle(value),
				},
			},
			getGrayArc(value),
		];
	} else if (value < 66) {
		arcs = [
			ARC_CHEST,
			{
				fill: 'url(#jaffayellow)',
				arc: {
					startAngle: getStartAngle(value),
					endAngle: getEndAngle(value),
				},
			},
			getGrayArc(value),
		];
	} else if (value < 100) {
		arcs = [
			ARC_CHEST,
			ARC_JAFFA,
			{
				fill: 'url(#yellowrio)',
				arc: {
					startAngle: getStartAngle(value),
					endAngle: getEndAngle(value),
				},
			},
			getGrayArc(value),
		];
	} else {
		arcs = [ARC_CHEST, ARC_JAFFA, ARC_YELLOW];
	}
	return arcs;
}

export default function BigNumber(props) {
	const ref = React.useRef(null);
	const size = 360;
	const [resize, setSize] = React.useState(100);

	const value = parseInt(props.value, 10);
	// small t logo
	const [state, setState] = React.useState(0);
	React.useEffect(() => {
		function onResize() {
			if (ref) {
				const w = ref.current.parentElement.clientWidth;
				if (w !== resize) {
					setSize(w);
				}
			}
		}
		onResize();
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [resize]);
	React.useEffect(() => {
		if (state !== Math.floor(value)) {
			setTimeout(() => {
				if (state < Math.floor(value)) {
					setState(state + 1);
				} else if (state > Math.floor(value)) {
					setState(state - 1);
				}

			}, 10);
		}
	}, [state, setState, value]);
	// NOTE: all svg is in view box, only the container div is resized
	const LOGO_RAYON = size / 2 - INNER_RADIUS;
	const xLogo = LOGO_RAYON * Math.cos(getEndAngle(state) - 0.5 * Math.PI);
	const yLogo = LOGO_RAYON * Math.sin(getEndAngle(state) - 0.5 * Math.PI);

	// circle with gradient
	const scale = (size * MAX_COEFF) / 200;
	const translate = `translate(${size / 2},${size / 2}) scale(${scale} ${scale})`;
	const arcs = getArcs(state);

	return (
		<div
			ref={ref}
			className="BigNumber text-center"
			style={{
				borderRadius: `${resize}px`,
				width: `${resize}px`,
				height: `${resize}px`,
			}}
		>
			<svg className="BigNumber__gauge" viewBox="0 0 360 360">
				<g>
					<circle cx="227" cy="321" r="8.8" fill={CHESTNUT_ROSE} />
					{arcs.map(a => (
						<path
							key={a.fill}
							fill={a.fill}
							transform={translate}
							d={arcGen({
								innerRadius: INNER_RADIUS,
								outerRadius: OUTER_RADIUS,
								startAngle: a.arc.startAngle,
								endAngle: a.arc.endAngle,
							})}
						/>
					))}
					<circle cx="328.5" cy="50%" r="8.8" fill="#ababab" />
				</g>
				{/*<TLogo
					transform={`translate(${size / 2 - (LOGO_SIZE * LOGO_SCALE) / 2},${
						size / 2 - (LOGO_SIZE * LOGO_SCALE) / 2
					}) translate(${xLogo},${yLogo}) scale(${LOGO_SCALE})`}
				/>*/}
				<defs>
					<linearGradient
						id="chestnutjaffa"
						gradientUnits="objectBoundingBox"
						x1="1"
						y1="1"
						x2="0"
						y2="0"
					>
						<stop offset="0%" stopColor={CHESTNUT_ROSE} />
						<stop offset="100%" stopColor={JAFFA} />
					</linearGradient>
					<linearGradient
						id="jaffayellow"
						gradientUnits="objectBoundingBox"
						x1="0"
						y1="1"
						x2="1"
						y2="0"
					>
						<stop offset="0%" stopColor={JAFFA} />
						<stop offset="100%" stopColor={LIGHTING_YELLOW} />
					</linearGradient>
					<linearGradient
						id="yellowrio"
						gradientUnits="objectBoundingBox"
						x1="0"
						y1="0"
						x2="1"
						y2="1"
					>
						<stop offset="0%" stopColor={LIGHTING_YELLOW} />
						<stop offset="100%" stopColor={RIO_GRANDE} />
					</linearGradient>
				</defs>
				<text
					x={state === 100 ? '60' : '90'}
					y="210"
					fontFamily="Gelasio"
					style={{
						fill: getScoreColor(state),
						color: getScoreColor(state),
						fontSize: state === 100 ? '130px' : '140px',
						lineHeight: '100px',
					}}
				>
					{state}
				</text>
				{props.children}
			</svg>
		</div>
	);
}
