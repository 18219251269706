import React from 'react';
import { checkEmail } from '../auth';
import './Login.css';

function getQueryParam() {
	const query = window.location.search
		.split('?')
		.filter(Boolean)
		.map(i => i.split('='))
		.find(q => q[0] === 'email');
	if (query) {
		return query[1] || '';
	}
	return '';
}

export default function Login({ status, onChange }) {
	const query = getQueryParam();
	const [email, setEmail] = React.useState(query);
	const [submitting, setSubmit] = React.useState(false);
	const [message, setMessage] = React.useState('');
	function doCheck(emailStr) {
		setSubmit(true);
		checkEmail(emailStr)
			.then(r => {
				if (r) {
					onChange();
				} else {
					setMessage(
						<span>
							For access, please enter a valid email.
						</span>,
					);
				}
			})
			.finally(() => {
				setSubmit(false);
			});
	}
	React.useEffect(() => {
		if (query) {
			doCheck(query);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	function onSubmit(e) {
		e.preventDefault();
		e.stopImmediatePropagation && e.stopImmediatePropagation();
		doCheck(email);
	}
	function onKeyDown(e) {
		if (e.keyCode === 13) {
			onSubmit(e);
		}
	}
	if (status === 0) {
		return (
			<div className="spinner-border text-dark" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		);
	}
	if (status === 2 || status === 3) {
		return (
			<div className="Login__field form-group required">
				<label>Validate your email</label>
				<div className="input-group">
					<input
						type="email"
						className="form-control"
						value={email}
						onChange={e => setEmail(e.target.value)}
						onBlur={onSubmit}
						onKeyDown={onKeyDown}
						aria-describedby="emailHelp"
					/>
					<div className="input-group-append">
						<button
							className="btn btn-outline-light"
							type="button"
							onClick={onSubmit}
							disabled={submitting}
						>
							<i className={submitting ? 'fa fa-spinner fa-spin' : 'fa fa-check'} />
						</button>
					</div>
				</div>
				{message ? (
					<div className="alert alert-danger">{message}</div>
				) : ""}
			</div>
		);
	}
}
