import React, { Fragment, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

import Message from './Message';
import Progress from './Progress';
import LogoDark from './LogoDark';
import { useDrop } from '../drop';

import './FileUpload.css';
import { useAuth, getKey } from '../auth';
import Login from './Login';
import { getCSRFHeader } from '../csrf';

const FileUpload = () => {
	const [status, onAuthChange] = useAuth();
	const [file, setFile] = useState('');
	const [separator, setSeparator] = useState('auto');
	const [customSeparator, setCustomSeparator] = useState('');

	const [filename, setFilename] = useState('');

	const [trustScoreReport, setTrustScoreReport] = useState(null);

	const [message, setMessage] = useState('');

	const [uploadPercentage, setUploadPercentage] = useState(0);

	const MAX_FILE_SIZE = 5 * 1024 * 1024;

	useEffect(() => {
		document.body.setAttribute('style', 'color: White; background-color: #323e48;');
		return () => {
			document.body.removeAttribute('style');
		}
	}, []);

	const onChange = e => {
		if (e.target.files[0]) {
			if (e.target.files[0].size > MAX_FILE_SIZE) {
				setFile('');
				setFilename('');
				setMessage('Your file exceeded the size limit. Please try again with a file under 5MB.');
			} else {
				setFile(e.target.files[0]);
				setFilename(e.target.files[0].name);
				setMessage('');
			}
		}
		setUploadPercentage(0);
		setTrustScoreReport(null);
	};
	const dropZone = useDrop({
		onDrop: e => onChange({ target: { files: e.dataTransfer.files } }),
	});
	const onSubmit = async e => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('file', file);
		formData.append('separator', separator === 'other' ? customSeparator : separator);
		formData.append('userid', getKey());
		const headers = getCSRFHeader();

		axios
			.post('/assessor/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					...headers,
				},
				onUploadProgress: progressEvent => {
					setUploadPercentage(
						parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
					);
					if (progressEvent.loaded === progressEvent.total) {
						setMessage('Data analysis in progress...');
					}
				},
			})
			.then(res => {
				const body = res.data;
				setTrustScoreReport(body);
				setMessage(
					`Trust Report successfully generated in ${
					body.file_metadata.duration_ms / 1000.0
					} seconds.`,
				);
			})
			.catch(error => {
				if (error.response) {
					setMessage(error.response.data.message);
				}
			});
	};

	const isFormValid = status === 1 && file && (separator !== 'other' || !!customSeparator);
	let invalidMessage;
	if (!file) {
		invalidMessage = 'you must provide a file';
	} else if (separator === 'other' && !customSeparator) {
		invalidMessage = 'you must provide a valid delimiter';
	}
	return (
		<div className="container upload d-print-none">
			<Fragment>
				<div className="row">
					<div className="Report__logo">
						<a href="https://www.qlik.com/" title="Qlik Data Integration, Data Quality, and Analytics Solutions"><LogoDark /></a>
					</div>
				</div>
				<div className="row">
					<div className="col-12 upload-row">
						<h2 className="upload__title text-center">Qlik Talend Trust Score&trade;</h2>
						<h2 className="upload__title text-center">Assessor for AI</h2>
						<p className="upload__subtitle text-center">
							Your report will be generated in just a few minutes.
							<br/>
							Upload any CSV file for analysis.
						</p>

						{message ? <Message msg={message} /> : null}

						<form onSubmit={onSubmit}>
							{status !== 1 && (<Login status={status} onChange={onAuthChange} />)}

							<p className="trial-text text-center">
								Don't have data right now?
								<br/>
								Download a sample dataset with hospital records:
							</p>
							<div className="trial-btns">
								<a href={`${process.env.PUBLIC_URL}/hospital_records_skewed.csv`} className="btn btn-outline-light">
									Skewed dataset
									<i className="fa fa-download" />
								</a>
								<a href={`${process.env.PUBLIC_URL}/hospital_records_unprotected.csv`} className="btn btn-outline-light">
									Unprotected dataset
									<i className="fa fa-download" />
								</a>
								<a href={`${process.env.PUBLIC_URL}/hospital_records_historical.csv`} className="btn btn-outline-light">
									Historical dataset
									<i className="fa fa-download" />
								</a>
							</div>
							<p />
							<div className="trial-btns">
								<a href={`${process.env.PUBLIC_URL}/hospital_records_balanced.csv`} className="btn btn-outline-light">
									Balanced dataset
									<i className="fa fa-download" />
								</a>
								<a href={`${process.env.PUBLIC_URL}/hospital_records_protected.csv`} className="btn btn-outline-light">
									Protected dataset
									<i className="fa fa-download" />
								</a>
								<a href={`${process.env.PUBLIC_URL}/hospital_records_updated.csv`} className="btn btn-outline-light">
									Updated dataset
									<i className="fa fa-download" />
								</a>
							</div>

							<p />

							<div className="form-group file-upload required">
								<label>Dataset (CSV file)</label>
								<div className="form-control-zone d-block text-center" ref={dropZone}>
									<i className="fa fa-upload" /> Drop your file or
									<label className="file-upload__label" htmlFor="customFile">
										browse
									</label>
									<input type="file" className="d-none" id="customFile" onChange={onChange} />
									{filename && <p>{filename}</p>}
								</div>

								{uploadPercentage > 0 && uploadPercentage < 100 ? (
									<Progress percentage={uploadPercentage} />
								) : null}
							</div>

							<ul className="trial-text text-left">
								<li>Max file size supported is 5MB</li>
								<li>For freshness assessment, record timestamp must be denoted by "update" or "modifed" in column name.</li>
							</ul>

							<div className="form-group">
								<label htmlFor="delimiter">Delimiter</label>
								<select
									className="form-control"
									id="delimiter"
									value={separator}
									onChange={e => setSeparator(e.target.value)}
								>
									<option value="auto">Detect automatically</option>
									<option value="comma">Comma</option>
									<option value="semicolon">Semicolon</option>
									<option value="tab">Tab</option>
									<option value="other">Other</option>
								</select>
							</div>

							{separator === 'other' && (
								<div className="form-group required">
									<label htmlFor="delimiter">Custom delimiter</label>
									<input
										className="form-control"
										type="text"
										id="custom-delimiter"
										value={customSeparator}
										onChange={e => setCustomSeparator(e.target.value)}
									/>
								</div>
							)}

							<input
								type="submit"
								disabled={!isFormValid}
								value="Analyze My Data"
								className="btn upload__btn btn-block mt-4"
								title={isFormValid ? 'Submit your file to get your trust score' : invalidMessage}
							/>
							<p />

							<p className="trial-text text-center">
								We will be in touch with you with more details.
							</p>
						</form>
					</div>
				</div>
				<div className="my-4">
					{trustScoreReport && <Navigate to={`/report/${trustScoreReport.report_id}`} />}
				</div>
			</Fragment>
		</div>
	);
};

export default FileUpload;
